html, 
body { font-family:$font-family-body; font-size: $global-font-size; font-weight:normal; color:$bodyColor; line-height: $global-line-height;
        margin:0; padding: 0 !important; border:0; vertical-align:baseline; height:100%; word-break:break-word; -webkit-text-size-adjust:100%;
  overflow: auto !important;
}

body { -webkit-text-size-adjust:none; background:$bodyBgColor; overflow-y:scroll; opacity:1; 
	&.active{ opacity:1; }
    * { outline:none; box-sizing:border-box; }
}

@media print {
   body {
        -webkit-print-color-adjust: economy;
        -webkit-print-color-adjust: exact;
   }
}

a { margin:0; padding:0; border:0; text-decoration:none; color:$bodyColor; word-wrap:break-word; font-size:13px;
    &:hover,
    &:active,
    &:focus { color:$aHoverColor; text-decoration:none !important; }
    img { display:block; border:none; }
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 { display:block; margin:0; padding:0; font-weight:normal; color:$bodyColor; line-height:1.4em; }

h1 { font-size:48px; margin:0 0 20px 0; }
h2 { font-size:26px; margin:0 0 15px 0; }
h3 { font-size:20px; margin:0 0 30px 0; }
h4 { font-size:19px; margin:0 0 10px 0; }
h5 { font-size:16px; margin:0 0 10px 0; }
h6 { font-size:12px; margin:0 0 4px 0; }

p { line-height:1.6em; margin:0 0 20px 0; font-size:14px; 
    a { color:$aColor; text-decoration:none; font-size:15px; }
}

hr { height:1px; margin:0; padding:0; border:0; background:$majorLightGrey; }

ol { }
ul { }
dl, dt, dd { margin:0; padding:0; }

table { width:100%; border-collapse:collapse; border-spacing:0; }
tr { }
td {
  vertical-align:top; padding:2px 0;
  a {
    color:$aHoverColor;
  }
}

th {
  font-size: 15px !important;
  color: $aColor !important;
}

/* custom */
strong { font-weight:bold; }
em { font-style:italic; }

.left { float:left; }
.right { float:right; }

.hide { display: none !important; }
.show { display: block !important; }

.align-left { text-align:left; }
.align-center { text-align:center; }
.align-right { text-align:right; }

.align-top { vertical-align:top !important; }
.align-middle { vertical-align:middle !important; }
.align-bottom { vertical-align:bottom !important; }

.clearfix { zoom:1; 
    &:after	{ content:"."; display:block; clear:both; width:0px; height:0px; visibility:hidden; font-size:0; }
}

.color-white {
  color:#fff!important;
}

.font-default {
  font-family:$font-family-body;
  font-size: $global-font-size;
  font-weight:normal;
}


.flex-center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start-center-item {
  display: flex;
  justify-content: start;
  align-items: center;
}

.flex-end-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin: 0 !important;
  }
}

.flex-end-item-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.files-dropzone {
  display: flex;
  button {
    margin: 0 !important;
  }
}
.file-remove-item {
  color: red;
  vertical-align: middle;
  display: inline-flex;
}

.display-inline-block {
  display: inline-block !important;
}

.export-div{
  margin-right: 20px;
}

.custom-link {
  color: #0a95ea !important;
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  word-wrap: break-word;
  font-size: 15px;
}
.custom-link:hover {
  cursor: pointer;
}

.pre-line {
  white-space: pre-line !important;
}
.flex-column-end {
  display: flex;
  flex-direction: column;
  align-content: flex-end!important;
  align-items: flex-end!important;
}