.public-page {
	background-color: #F5F5F5;
}

.private-page { }

.breadcrumb__container {
	color: #000;
	margin: 0;
	padding: 10px 20px;
	border-radius: 0;

	span {
		display: inline-block;
		padding: 5px;
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 1.6;
		letter-spacing: 0.0075em;
		color: #fff;

		&:first-child {
			padding-left: 0;
		}

		a {
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 1.6;
			letter-spacing: 0.0075em;
			display: block;
			color: #fff;
		}
	}
}

.breadcrumb__separate {
	font-size: 1.5rem !important;
}

.account-info {
	display: flex;
	flex-direction: row;
	padding: 5px;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	color: #fff;
	button{
		color: #fff;
	}
}

.name-role-info {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	.name {
		font-size: 16px;
	}
	.role {
		font-size: 13px;
	}
}
//SIDE BAR
.sidebar__container {
	.sidebar__container_logo {
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		img {
			width: 190px;
			opacity: 1;
			filter: brightness(0) invert(1);
		}
		.sidebar__container_desc {
			width: 190px;
		}
		.sidebar__container_title {
			font-size: 20px;
			font-weight: bold;
			margin: 15px 0 0 0;
		}
	}

	.sidebar-menu {
		ul > a {
			border-left: 5px solid $sideBarBgColor;
			.menu-item-icon {
				color: #878796;
				min-width: 40px !important;
			}
			> svg, span {
				color: #878796;
			}
		}
		.active {
			border-left: 5px solid $sideBarActiveBgColor;
			background-color: $sideBarActiveBgColor;
			.menu-item-icon {
				color: #fff;
			}
			> svg, span {
				color: #fff;
			}
		}
	}
}

//CONTAINER
.main__container {
	width: 100%;
	padding: 30px;
}

.footer__container {
	height: $containerFooterHeight;
	width: 100%;
	background-color: $containerFooterBgColor;
	color: $containerFooterColor;
	font-size: 14px;
	font-weight: bold;
	overflow: hidden;
	position: fixed;
	bottom: 0;
}

.full-height {
	height: 100%;
}
.full-width {
	width: 100%;
}
.overflow-x-auto {
	overflow-x: auto;
}