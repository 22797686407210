// Typography
$font-family-body: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font-family-titles: "Helvetica Neue",Helvetica,Arial,sans-serif;

$font-family-body-chinese: 'Microsoft JhengHei', sans-serif;
$font-family-titles-chinese: 'Microsoft JhengHei', sans-serif;

$global-font-size: 15px;
$global-line-height: 1.2em;

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

// Header
$headerDesktopHeight: 75px;

// Body
$bodyColor:#000;
$bodyBgColor: #fff;
$corporateRedColor:#da291c;
$majorLightGrey:#e0e0e0;
$majorDarkGrey:#717171;
$majorDarkGreyHover:#5e5e5e;

// Form
$formBtnBgColor:#fd7517;
$formBtnHoverBgColor:#c95d12;
$primaryBtnBgColor: #2196F3;
$primaryBtnHoverBgColor: #45a7f5;
$importBtnBgColor:#0a95ea;
$importBtnHoverBgColor:#087bc2;
$exportBtnBgColor:#A300D9;
$exportBtnHoverBgColor:#A300A0;
$sendBtnBgColor:#36D900;
$sendBtnHoverBgColor:#269103;

$formBtnColor:#fff;

// Hyperlink
$aColor:#0a95ea;
$aHoverColor:#0a95ea;

// Menu
$mainMenuHoverYellow: #fffeaf;
// #153568 #4b689a
// #d40000 #fe5353
// #c45502 #e67e00
// #2d5e30 #428644
// #be9500 #efa007
// #018d96 #42a8b0

// Page background colors
$bgGreenYellow: #C1FE01; 
$bgGreenBlue: #00E2E1;
$bgBlueGreen: #42A9B0;
$bgLavender: #E6E6FA;

// SLICK
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: white !default;
$slick-dot-color-active: grey !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 16px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

// Side menu
$sideBarWidth: 260px;
$sideBarBgColor: #3D3834;
$sideBarActiveBgColor: #7F6249;

// Header
$headerMenuColor: #fff;
$containerHeaderHeight: 60px;
$containerHeaderColor: #ABADB8;

// Footer
$containerFooterBgColor: #F5F5F5;
$containerFooterHeight: 60px;
$containerFooterColor: #000;